<template>
  <div class="pageWrapper">
    <div class="topbar">
      <div style="display: flex">
        <H2>Customer Settings</H2>
      </div>
    </div>
  </div>
</template>

<script>
import H2 from '@/layout/typography/H2'
export default {
  components: { H2 },
}
</script>

<style>
</style>